import { useSnackbar } from 'notistack'
import logger from '../logger'
import useAuth from './useAuth'
import { isAuthErrorCode } from '../helpers'

const snackbarOptions = {
  variant: 'error'
}

const useErrorHandler = () => {
  const { logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  return (error) => {
    logger.error(`Handled error: ${JSON.stringify(error.error)}`)

    if (isAuthErrorCode(error.status)) {
      return logout()
    }

    // Don't show error messages for static file 404s
    if (error.status === 404 && error.url?.match(/\/robots\d*\.txt$/)) {
      logger.debug('Ignoring robots.txt 404 error')
      return
    }

    if (error.message) {
      enqueueSnackbar(`Something went wrong. ${error.message}`, snackbarOptions)
    } else {
      enqueueSnackbar(`Something went wrong, error code: ${error.status || 'Unknown'}`, snackbarOptions)
    }
  }
}

export default useErrorHandler
